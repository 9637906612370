<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Заказы</h5>
        <DataTable
            :value="orders"
            dataKey="id"
        >
          <Column field="id" header="ID" :sortable="true" style="min-width:6rem"></Column>
          <Column field="createdAt" header="Дата" :sortable="true" style="min-width:6rem"></Column>
          <Column field="fName" header="Фамилия" :sortable="true" style="min-width:6rem"></Column>
          <Column field="iName" header="Имя" :sortable="true" style="min-width:6rem"></Column>
          <Column :exportable="false" style="min-width:8rem">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="order = slotProps.data" />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KindressaOrders",
  data () {
    return {
      orders: [],
      order: null,
      editingRows: []
    }
  },
  async mounted() {
    const orders = await this.axios.get('/api/orders')
    console.log(orders.data)
    this.orders = orders.data['hydra:member']
  },
}
</script>

<style scoped>

</style>
